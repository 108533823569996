<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">{{ title }}</div>
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="form-group">
                <label for="username">Username</label>
                <input type="text" class="form-control" id="username" v-model="username" required>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model="password" required>
              </div>
              <button type="submit" class="btn btn-primary">Login</button>
              <div v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginComponent',
  data() {
    return {
      title: 'Login',
      username: '',
      password: '',
      errorMessage: '' // Add a new data property to store the error message
    }
  },
  methods: {
    async onSubmit() {
      this.errorMessage = '' // Clear the error message
      try {
        await this.$root.login(this.username, this.password)
        console.log('Logged in!')
        this.$router.push('/')
      } catch (error) {
        this.errorMessage = error.message // Set the error message if there's an error
      }
    }
  }
}
</script>

<style scoped>
.card {
  margin-top: 50px;
}
</style>
