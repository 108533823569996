import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css'

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'


import App from './App.vue'
import HomeComponent from './components/HomeComponent.vue'
import Step1Component from './components/Step1Component.vue'
import Step2Component from './components/Step2Component.vue'
import StepResearchComponent from './components/StepResearchComponent.vue'
import PrecedentsComponent from './components/PrecedentsComponent.vue'
import LoginComponent from './components/LoginComponent.vue'
import State from './State';

import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import axios from 'axios'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'http://localhost:8000/';

const routes = [
  { path: '/login', component: LoginComponent },
  { path: '/', component: HomeComponent },
  { path: '/precedents', component: PrecedentsComponent },
  { path: '/step1', component: Step1Component, meta: { requiresAuth: true }  },
  { path: '/step2', component: Step2Component, props: { stepInit: 2, title: "Deconstruction" } },
  { path: '/step3', component: Step2Component, props: { stepInit: 3, title: "Disassociation" } },
  { path: '/step4', component: Step2Component, props: { stepInit: 4, title: "Questions" } },
  { path: '/step5', component: Step2Component, props: { stepInit: 5, title: "Precedents" } },
  { path: '/step6', component: StepResearchComponent },
  { path: '/step7', component: Step2Component, props: { stepInit: 7, title: "Recombine" } },
  { path: '/step8', component: Step2Component, props: { stepInit: 8, title: "Strategy" } },

]


const app = createApp(App)
app.config.globalProperties.$app = app


const router = createRouter({
  history: createWebHistory(),
  routes 
})

app.use(router)

router.beforeEach((to, from, next) => {
  // check whether the route requeste is /login
  let isLogin = to.path === '/login';
  //logout()
  const isAuthenticated = State.isAuth
  //if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
  if (!isLogin && !isAuthenticated) {
      next('/login')
      //login()
  } else {
    next()
  }
})


let resturl = "http://localhost:8000"
if (process.env.NODE_ENV === 'production') {
  console.log("running in production mode")
  resturl = ""
}

let api = axios.create({
  baseURL: resturl,
  //timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true // This will allow cookies to be included in requests.
});

api.interceptors.response.use(
  response => response,
  error => {
    console.log("EEEEEEEEEEEEEEEEEEEEEEEEEE", error.response.status)
    if (error.response.status === 418) {
      // Handle authentication error
      console.error('Authentication error:', error)
      throw new Error('Invalid username or password')
    } else {
      return Promise.reject(error)
    }
  }
)


app.component('vue-cookie-accept-decline', VueCookieAcceptDecline);


app.mixin({
  data() {
    return {
      state: new State(),
      resturl: resturl,
      api: api,
      isAuth: false
    }
  },
  methods: {
    setAuth(isAuth) {
      this.isAuth = isAuth
      State.isAuth = isAuth
    },
    resetState() {
      this.state = new State()
    },
    async login(username, password) {
      try {
        //const headers = { Authorization: `Basic ${btoa(`${username}:${password}`)}` }
console.log("PROD/DEV:",process.env.NODE_ENV)

        //username = 'user1';
        //password = 'pass1';
        let token = btoa(username + ':' + password);
            
        const response = await api.post('/login', {}, {headers: { 'Authorization': 'Basic ' + token }});
        //const response = await api.post('/login', {headers: { 'Authorization': 'Basic ' + token } }, {});
        localStorage.setItem('username', username);
        this.setAuth(true)
        return response.data;
      } catch (error) {
        console.error('Error during login:', error);
        throw error;
      }
    },

    async logout() {
      try {
        const response = await api.get('/logout');
        // Here, you can clear the username from local storage, the cookie, or the Vuex store
        // to clear the user's session on the client side. The actual session,
        // however, is deleted by the FastAPI server.
        localStorage.removeItem('username');
        this.setAuth(false)
        this.resetState()
        this.$router.push('/login')
        return response.data;
      } catch (error) {
        console.error('Error during logout:', error);
        throw error;
      }
    },

    async getUserData() {
      try {
        //this.$root.logout()
        const response = await api.get('/users/me/');
        return response.data;
      } catch (error) {
        console.error('Error getting user data:', error);
        throw error;
      }
    }



  },
  created() {
    this.setAuth(localStorage.getItem('username') !== null)
  }

})


app.mount('#app')
