
<template>
  <div class="container">
    <h1>Framing</h1>
    <textarea class="form-control" v-model="getState().problem"></textarea>
    <button class="btn btn-primary" @click="submit('') " :disabled="isSubmitting">
      <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Submit
    </button>
  </div>



<!-- Modal -->
<div class="modal fade" id="promptModal" tabindex="-1" aria-labelledby="promptModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="promptModalLabel">Prompt</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <textarea id="promptTextarea" class="form-control" v-model="modalDescription"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- submit calls submit(content of text area) -->
        <button @click="submit(this.modalDescription)" type="button" class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-- ERROR MODAL -->
<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="errorModalLabel">Error</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>{{ errorDescription }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


</template>

<script>

import bootstrap from 'bootstrap/dist/js/bootstrap.js';

export default {
  name: 'Step1Component',
  data() {
    return {
      step: 1,
      modalDescription: '',
      description: '',
      isSubmitting: false
    }
  },
  methods: {
    async submit(prompt="") {
      console.log("submit", prompt)
      let url = "/options";
      try {
        this.isSubmitting = true
        let choice = { step: this.step, description: this.description }
        if (this.step == 1) {  // first step
          choice.description = this.getState().problem
        }
        console.log("---choice", choice, prompt)
        if (prompt.trim() != "") {
          choice.prompt = prompt
        }
        this.getState().setChoice(choice)
        const choices = {choices : this.getState().getChoices()}
        const response = await this.$root.api.post(url, choices)
        console.log("response data:",response.data)
        if (response.data.prompt) {
          this.showPromptModal(response.data.prompt)
        } else {
          console.log("go to step ", this.step + 1, response.data)
          this.getState().setCurrent(this.step, response.data)
          this.$router.push(`/step${this.step + 1}`)
        }
      } catch (error) {
        console.error("eeee", error)
        // open a bootstrap error modal with the error message
        // Here's an example using Bootstrap:
        const modal = new bootstrap.Modal(document.getElementById('errorModal'))
        if (error.message != undefined)
          this.errorDescription = `Error ${error.message}`
        else
          this.errorDescription = `Error ${error.response.status}: ${error.response.data.detail}`
        modal.show()
      } finally {
        this.isSubmitting = false
      }
    },
    showPromptModal(prompt) {
      // Open a modal with the prompt in a text area
      // You can use a library like Bootstrap or Vue.js to create the modal
      // Here's an example using Bootstrap:
      const modal = new bootstrap.Modal(document.getElementById('promptModal'))
      this.modalDescription = prompt
      modal.show()
    },
    getState() {
      //console.log("getState", this.$root.state)
      return this.$root.state
    }
  }
}
</script>

<style>

/* Increase the size of the modal */
.modal-dialog {
  max-width: 800px;
}

/* Increase the size of the textarea */
#promptTextarea {
  height: 300px;
}
</style>

