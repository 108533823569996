// export class State
export default

class State {
    static isAuth = false

    constructor() {
        this.choices = []
        this.current = 0
        this.results = []
        this.problem = ""
        this.precedents = []
    }

    setChoice(choice) {
        this.choices[choice.step] = choice
        console.log("qui si sta chiamando la funzione setChoice", choice)
        // remove next choices
        this.choices = this.choices.slice(0, choice.step + 1)
        // same for results
        this.results = this.results.slice(0, choice.step + 1)
    }

    getChoice(step) {
        return this.choices[step]
    }

    getChoices() {
        // return this.choices filtered null
        return this.choices.filter(function (el) {
            return el != null;
        });
    }

    setCurrent(step, result) {
        this.current = step
        console.log(result)
        this.results[step] = result.options
    }

    addPrecedent(precedent) {
        this.precedents.push(precedent)
    }

    resetPrecedents() {
        this.precedents = []
    }

    removePrecedent(precedentIdInArray) {
        this.precedents.splice(precedentIdInArray, 1)
    }

}