<template>
    <!-- Welcome message and a Start button. Bootstrap style -->
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>Welcome to PDI</h1>
                <p>Click the button below to start</p>
                <button class="btn btn-primary" v-on:click="start">Start</button>
            </div>
        </div>
    </div>
</template>
    
    
<script>
    export default {
        name: 'HomeComponent',
        methods: {
            start() {
                this.$router.push('/step1')
            }
        }
    }
</script>    
