<template>
  <div>
    <div v-if="this.$root.isAuth">
      <div class="list-group" id="sidebar">
        <router-link to="/" class="list-group-item">Home</router-link>
        <router-link to="/precedents" class="list-group-item">Precedents</router-link>
        <router-link v-for="n in currentSteps" :key="n" :to="`/step${n}`" class="list-group-item">Step {{ n }}</router-link>
        <br><br><br>
        <button class="btn btn-primary" @click="this.$root.logout()">Logout</button>
      </div>
    </div>
    
    <div id="content">
      <router-view></router-view>
    </div>
  </div>
  <footer>
    <vue-cookie-accept-decline
  :debug="false"
  :disableDecline="true"
  :showPostponeButton="false"
  @clicked-accept="cookieClickedAccept"
  @clicked-decline="cookieClickedDecline"
  @clicked-postpone="cookieClickedPostpone"
  @removed-cookie="cookieRemovedCookie"
  @status="cookieStatus"
  elementId="myPanel1"
  position="bottom-left"
  ref="myPanel1"
  transitionName="slideFromBottom"
  type="floating"
>
  <!-- Optional -->
  <template #postponeContent>&times;</template>

  <!-- Optional -->
  <template #message>
    We use cookies to ensure you get the best experience on our website.
    <a href="https://cookiesandyou.com/" target="_blank">Learn More...</a>
  </template>

  <!-- Optional -->
  <template #declineContent>Opt Out</template>

  <!-- Optional -->
  <template #acceptContent>Got It!</template>
</vue-cookie-accept-decline>
  </footer>
</template>

<script>

export default {
  name: 'App',
  computed: {
    getUsername() {
      return this.$root.getUserData().username
    },
    currentSteps() {
      return Array.from({ length: this.$root.state.current +1}, (_, i) => i + 1)
    },
  },
  data() {
    return {
      //modalDescription: ''
    }
  },
  methods: {
  },
  components: {
  },
  mounted() {
    if (!this.$root.isAuth) {
      this.$router.push('/login')
    }
  },
  watch: {
    'this.$root.isAuth': function() {
      console.log('isAuth changed')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#sidebar {
  width: 200px;
  float: left;
}

#content {
  margin-left: 200px;
}

@import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';


</style>
