<template>
  <div class="container">
    <h2>Precedents</h2>
    <div class="list-group choices-container">
      <div v-if="getState().precedents.length === 0" class="list-group-item">
        No precedent selected so far
      </div>
      <div v-else v-for="(precedent, index) in getState().precedents" :key="index" class="list-group-item">
        <div style="white-space: pre-line;">
          <div v-if="precedent.length > 50">
            <span v-if="!expanded[index]">{{ precedent.substring(0, 50) }}...</span>
            <span v-else>{{ precedent }}</span>
            <br/>
            <a href="#" @click.prevent="toggleExpansion(index)">
              {{ expanded[index] ? 'collapse' : 'expand' }}
            </a>
          </div>
          <div v-else>
            {{ precedent }}
          </div>
        </div>
        <br/>
        <div class="btn-group float-right" role="group">
          <button class="btn btn-danger" @click="removePrecedent(index)">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </div>
    </div>
    <button :class="strategyButtonClass" @click="submit_for_strategy" :disabled="getState().precedents.length !== num_required || isSubmitting" >
      <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ strategyButtonText }}
    </button>
  </div>


<!-- Modal -->
<div class="modal fade" id="promptModal" tabindex="-1" aria-labelledby="promptModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="promptModalLabel">Prompt</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <textarea id="promptTextarea" class="form-control" v-model="modalDescription"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- submit calls submit(content of text area) -->
        <button @click="submit(this.modalDescription)" type="button" class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>


</template>

<script>
import Step1Component from './Step1Component.vue'

export default {
  name: 'PrecedentsComponent',
  extends: Step1Component,
  data() {
    return {
      expanded: [],
      num_required: 3
    }
  },
  created() {
    this.expanded = new Array(this.getState().precedents.length).fill(false)
  },
  methods: {
    removePrecedent(index) {
      if (window.confirm('Are you sure you want to remove this precedent?')) {
        this.getState().removePrecedent(index)
      }
    },
    toggleExpansion(index) {
      const newExpanded = [...this.expanded]
      newExpanded.splice(index, 1, !newExpanded[index])
      this.expanded = newExpanded
    },
    submit_for_strategy() {
      console.log(this.getState().precedents)
      this.description = this.getState().precedents.join("___###___")
      this.step = 6
      this.submit()
    }
  },
  computed: {
    strategyButtonText() {
      if (this.getState().precedents.length === this.num_required) {
        return 'Recombine'
      } else {
        return `Select ${this.num_required} precedents`
      }
    },
    strategyButtonClass() {
      if (this.getState().precedents.length === this.num_required) {
        return 'btn btn-primary'
      } else {
        return 'btn btn-secondary disabled'
      }
    }
  }
}
</script>