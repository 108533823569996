<template>
  <div class="container">
    <h2>{{ title }}</h2>
    <div class="list-group choices-container">
      <div v-for="(choice, index) in getState().results[this.step-1]" :key="index" class="list-group-item"  style="white-space: pre-line;">
        <div v-if="editingIndex == null || editingIndex !== index">{{ choice }}</div>
        <div v-else>
          <textarea class="form-control" v-model="getState().results[this.step-1][index]"></textarea>
        </div>
        <div v-if="mustShowNext()" class="btn-group float-right" role="group">
          <button v-if="editingIndex == null || editingIndex !== index" type="button" class="btn btn-secondary" @click="startEditing(index)"  :disabled="isSubmitting"><i class="fas fa-pen"></i></button>
          <button v-else type="button" class="btn btn-secondary" @click="stopEditing()"><i class="fas fa-times"></i></button>
          <button type="button" class="btn btn-primary" @click="onChoiceSelected(choice)" :disabled="isSubmitting">
            <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

<!-- Modal -->
<div class="modal fade" id="promptModal" tabindex="-1" aria-labelledby="promptModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="promptModalLabel">Prompt</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <textarea id="promptTextarea" class="form-control" v-model="modalDescription"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- submit calls submit(content of text area) -->
        <button @click="submit(this.modalDescription)" type="button" class="btn btn-primary" data-bs-dismiss="modal">Submit</button>
      </div>
    </div>
  </div>
</div>

<!-- ERROR MODAL -->
<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="errorModalLabel">Error</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>{{ errorDescription }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


</template>

<script>
import Step1Component from './Step1Component.vue'

export default {
  name: 'Step2Component',
  extends: Step1Component,
  props: ['stepInit', 'title'],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.step = vm.stepInit // Set the step variable to the value of stepInit before the component is created
    })
  },
  data() {
    return {
      choices: ['Choice 1', 'Choice 2', 'Choice 3'],
      step: this.stepInit,
      editingIndex: null,
    }
  },
  watch: {
    stepInit(newVal) {
      console.log("stepInit changed", newVal)
      this.step = newVal;
    }
  },
  methods: {
    onChoiceSelected(choice) {
      this.description = choice
      this.stopEditing()
      this.submit("")
    },
    startEditing(index) {
        this.editingIndex = index
    },
    stopEditing() {
      this.editingIndex = null
    },
    mustShowNext() {
      // if I'm on strategy page, don't show edit/next buttons
      return this.step != 8
    }
  },
}
</script>

<style>
.choices-container { /* Define a CSS class for the choices container */
  text-align: left; /* Align the text to the left */
}
</style>