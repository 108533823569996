<template>
  <div class="container">
    <h2>Research precedent</h2>
    <div class="list-group choices-container">
      <div v-for="(choice, index) in getState().results[this.step-1]" :key="index" class="list-group-item" style="white-space: pre-line;">
        <div v-if="editingIndex == null || editingIndex !== index">{{ choice }}</div>
        <div v-else>
          <textarea class="form-control" v-model="getState().results[this.step-1][index]"></textarea>
        </div>
        <div class="btn-group float-right" role="group" v-if="!clicked[index]">
          <button class="btn btn-primary" @click="onAddClicked(index)">Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Step1Component from './Step1Component.vue'

  
export default {
  name: 'StepResearchComponent',
  extends: Step1Component,
  data() {
    return {
      choices: ['Choice 1', 'Choice 2', 'Choice 3'],
      step: 6,
      editingIndex: null,
      clicked: {}, // Add a clicked object to keep track of which results have been clicked
    }
  },
  methods: {
    addPrecedent(precedent) {
      console.log('Adding precedent', precedent)
      this.getState().addPrecedent(precedent)
      this.clicked = {} // Reset the clicked object when a precedent is added
    },
    onAddClicked(index) {
      this.addPrecedent(this.getState().results[this.step-1][index])
      this.clicked[index] = true // Set the clicked property to true for the clicked result
    },
  },
}

</script>
  
  <style>
  .choices-container { /* Define a CSS class for the choices container */
    text-align: left; /* Align the text to the left */
  }
  </style>